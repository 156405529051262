import {Component, HostListener, OnInit} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {AddressesService} from "../../../service/settings/addresses.service";

@Component({
    selector: 'app-addresses-settings',
    templateUrl: './addresses-settings.component.html',
    styleUrls: ['./addresses-settings.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class AddressesSettingsComponent implements OnInit {
    addresses;
    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private addressesService: AddressesService) {

    }


    ngOnInit() {
      this.getAccountsList();
    }

    getAccountsList() {
        this.loaderService.show();
        this.addressesService.getAddresses().subscribe(data => {
            this.loaderService.hide();

            try {
                this.addresses = [];
                if(data.status){
                    this.addresses = data.result;
                }
            } catch (e) {

            }
        }, (error) => {
            this.loaderService.hide();
        })
    }

    askDelete(address) {
        this.popupComponent.showPopupAgreement({text: `<b>Delete address:</b> </br></br> ${address.address}`}, (status) => {
            if (status) {
                this.delete(address.id);
            }
        });
    }

    delete(id) {
        this.addressesService.deleteAddress(id).subscribe(data => {
            this.loaderService.hide();
            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.getAccountsList();
                }  else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

}
