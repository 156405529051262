import {Component, NgZone, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {StripeService} from "../../../../service/stripe/stripe.service";

@Component({
    selector: 'app-new-card',
    templateUrl: './new-card.component.html',
    styleUrls: ['./new-card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewCardComponent implements OnInit {
    newCardForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private userCardService: UserCardService,
                private loaderService: LoaderService,
                private router: Router,
                private ngZone: NgZone,
                private stripeService:StripeService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newCardForm = this.formBuilder.group({
            card_number: [null, Validators.required],
            exp: [null, Validators.required],
            cvv: [null, Validators.required],
            card_nickname: [null, Validators.required]
        });
    }

    // onSubmit() {
    //     this.popupComponent.showPopupAgreement({text: `Add address ${address} to available addresses${saveText}?`}, (status) => {
    //         if (status) {
    //             this.submit();
    //         }
    //     });
    // }

    onSubmit() {
        const number = this.newCardForm.controls.card_number.value;
        const exp = this.newCardForm.controls.exp.value;
        const cvv = this.newCardForm.controls.cvv.value;
        const nickname = this.newCardForm.controls.card_nickname.value;

        let expMonth = exp.substr(0, 2);
        let expYear = exp.substr(2, 3);
        if(expMonth != '' && expYear != ''){
            this.loaderService.show();
            this.userCardService.addCard(number, expMonth, expYear, cvv, nickname).subscribe(data => {
                this.loaderService.hide();
                try {
                    if (data.status) {
                        this.loaderService.hide();

                        this.popupComponent.showPopupSuccess({text: data.message});

                        this.newCardForm.reset();

                        this.router.navigate(['/home/settings/card']);

                        return;
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hide();
                    this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                }

            }, (error) => {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            });


            // this.loaderService.show();
            // this.stripeService.createCardToken(number,cvv,expMonth,expYear,(response) => {
            //     this.ngZone.run( () => {
            //         try {
            //             if (response.error) {
            //                 this.loaderService.hide();
            //                 this.popupComponent.showPopupError({text: response.error.message});
            //             } else {
            //
            //                 this.userCardService.addCard(number, expMonth, expYear, cvv, nickname, response.id).subscribe(data => {
            //                     this.loaderService.hide();
            //                     try {
            //                         if (data.status) {
            //                             this.loaderService.hide();
            //                             this.popupComponent.showPopupSuccess({text: data.message});
            //                             this.newCardForm.reset();
            //                             this.router.navigate(['/home/settings/card']);
            //                             return;
            //                         } else {
            //                             this.popupComponent.showPopupError({text: data.message});
            //                         }
            //                     } catch (e) {
            //                         this.loaderService.hide();
            //                         this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            //                     }
            //
            //                 }, (error) => {
            //                     this.loaderService.hide();
            //                     this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            //                 });
            //             }
            //         } catch (e) {
            //             this.loaderService.hide();
            //             this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            //         }
            //     });
            // });

        } else {
            this.popupComponent.showPopupError({text: 'Incorrect data format'});
        }

    }
}
