/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./card-menu.component";
import * as i5 from "../../../../service/settings/userCard.service";
import * as i6 from "../../../../service/loader.service";
import * as i7 from "../../../../shared/popup/popup.component";
var styles_CardMenuComponent = [i0.styles];
var RenderType_CardMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardMenuComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_CardMenuComponent as RenderType_CardMenuComponent };
function View_CardMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "page page--quaternary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "page__back"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 17, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 16, "div", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "a", [["class", "navigation__button button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 2), (_l()(), i1.ɵted(-1, null, [" EDIT CARD "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" DELETE "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(23, 2), (_l()(), i1.ɵted(-1, null, [" VERIFY "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/home/settings/card"); _ck(_v, 3, 0, currVal_2); var currVal_6 = _ck(_v, 13, 0, "/home/settings/card/edit", _co.cardId); _ck(_v, 12, 0, currVal_6); var currVal_9 = _ck(_v, 23, 0, "/home/settings/card/verify", _co.cardId); _ck(_v, 22, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.card.name; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 22).target; var currVal_8 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_7, currVal_8); }); }
export function View_CardMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-menu", [], [[40, "@slideToLeft", 0]], null, null, View_CardMenuComponent_0, RenderType_CardMenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.CardMenuComponent, [i5.UserCardService, i6.LoaderService, i2.ActivatedRoute, i2.Router, i7.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var CardMenuComponentNgFactory = i1.ɵccf("app-card-menu", i4.CardMenuComponent, View_CardMenuComponent_Host_0, {}, {}, []);
export { CardMenuComponentNgFactory as CardMenuComponentNgFactory };
