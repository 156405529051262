import { USER_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserStatusService = /** @class */ (function () {
    function UserStatusService(http) {
        this.http = http;
        this.config = USER_CONFIG;
    }
    UserStatusService.prototype.getUserStatus = function () {
        return this.http.post(this.config.status, {});
    };
    UserStatusService.prototype.freeze = function () {
        return this.http.post(this.config.freeze, {});
    };
    UserStatusService.prototype.activate = function () {
        return this.http.post(this.config.activate, {});
    };
    UserStatusService.prototype.delete = function () {
        return this.http.post(this.config.delete, {});
    };
    UserStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserStatusService_Factory() { return new UserStatusService(i0.ɵɵinject(i1.HttpClient)); }, token: UserStatusService, providedIn: "root" });
    return UserStatusService;
}());
export { UserStatusService };
