<div class="page page--quaternary">
    <div class="page__inner">
        <a [routerLink]="['/home/settings']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">PAYPAL</h1>
        <div class="page__centered">
            <a [routerLink]="['/home/settings/paypal/add']" class="button snake-btn is-notouched">
                ADD ACCOUNT
                <svg><rect></rect></svg>
            </a>
        </div>
        <div class="page__body">
            <div class="cards">
                <div *ngFor="let account of accounts" class="cards__item">
                    <div class="cards__left">
                        <div class="cards__title">{{account.email}}</div>
                    </div>
                    <div class="cards__right">
                        <button (click)="askDelete(account)" class="cards__btn">
                            <svg width="17" height="22">
                                <use xlink:href="../../../../assets/images/sprite.svg#delete"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

