import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {UserCardService} from "../../../service/settings/userCard.service";
import {USER_STATUS} from "../../../config/user_status";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CardComponent implements OnInit {
    cards;
    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private userCardService: UserCardService) {

    }


    ngOnInit() {
      this.getCardList();
    }

    getCardList() {
        this.loaderService.show();

        this.userCardService.getCards().subscribe(data => {
            this.loaderService.hide();

            try {
                this.cards = [];
                if(data.status){

                    this.cards = data.result;

                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })

    }

    askDelete(card) {
        this.popupComponent.showPopupAgreement({text: `<b>Delete Card:</b> </br></br> ${card.name} </br> ending in ${card.ending}`}, (status) => {
            if (status) {
                this.deleteCard(card.id);
            }
        });
    }

    deleteCard(id) {
        this.userCardService.deleteCard(id).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.getCardList();
                }  else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }



        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

    viewCardInfo(card) {
        if(!card.verify){
            this.router.navigate(['/home/settings/card/card-menu', card.id]);
        }

        return false;
    }


}
