import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";

@Component({
    selector: 'app-card-menu',
    templateUrl: './card-menu.component.html',
    styleUrls: ['./card-menu.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CardMenuComponent implements OnInit {
    card;
    cardId;
    constructor(private userCardService: UserCardService,
                private loaderService: LoaderService,
                private route: ActivatedRoute,
                private router: Router,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo();
        });
    }

    getCardInfo() {
        this.loaderService.show();
        this.userCardService.getCardInfo(this.cardId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.card = data.result;
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }



        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

    addNewCard() {

    }

    askDelete() {
        this.popupComponent.showPopupAgreement({text: `<b>Delete Card:</b> </br></br> ${this.card.description} </br> ending in ${this.card.last_four}`}, (status) => {
            if (status) {
                this.deleteCard(this.card.id);
            }
        });
    }

    deleteCard(id) {
        this.loaderService.show();
        this.userCardService.deleteCard(id).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.router.navigate(['/home/settings/card']);
                }  else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }
}
