/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./investments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./investments.component";
import * as i3 from "../../shared/popup/popup.component";
var styles_InvestmentsComponent = [i0.styles];
var RenderType_InvestmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvestmentsComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_InvestmentsComponent as RenderType_InvestmentsComponent };
export function View_InvestmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "page page--tertiary"], ["style", "background-image: url(../../../assets/images/bitcoingirl.png)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lending"])), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "investment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "investment__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["INVESTMENT "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" BALANCE"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "investment__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["40 mBTC"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "investment__graph"], ["style", "background-image: url(../../../assets/images/graph.png)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["40%"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "investment__footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TIER N"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "coming-soon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["COMING SOON"]))], null, null); }
export function View_InvestmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investments", [], [[40, "@slideToRight", 0]], null, null, View_InvestmentsComponent_0, RenderType_InvestmentsComponent)), i1.ɵdid(1, 114688, null, 0, i2.InvestmentsComponent, [i3.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var InvestmentsComponentNgFactory = i1.ɵccf("app-investments", i2.InvestmentsComponent, View_InvestmentsComponent_Host_0, {}, {}, []);
export { InvestmentsComponentNgFactory as InvestmentsComponentNgFactory };
