<div class="page page--quaternary">
    <div class="page__inner">
        <a [routerLink]="['/home/settings/addresses']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">ADD ADDRESS</h1>
        <div class="page__form">
            <form [formGroup]="newAddressForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="address"
                           placeholder="Address"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="newAddressForm.invalid">
                        SAVE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

