import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../shared/popup/popup.component";

@Component({
    selector: 'app-investments',
    templateUrl: './investments.component.html',
    styleUrls: ['./investments.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class InvestmentsComponent implements OnInit {

    constructor(private popupComponent: PopupComponent) {

    }


    ngOnInit() {

    }
}
