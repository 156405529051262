import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";

@Component({
    selector: 'app-mail-check',
    templateUrl: './mail-check.component.html',
    styleUrls: ['./mail-check.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class MailCheckComponent implements OnInit {
    sellForm: FormGroup;
    availableBalance = 0;
    addresses = [];
    checkedAddress = null;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private existingUserService: ExistingUserService) {

    }

    ngOnInit() {
        this.getPrizesInfo();

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
            address: [null, Validators.required],
        });

    }

    getPrizesInfo(){
        this.loaderService.show();
        this.prizesService.getCheckAddresses().subscribe(data => {
            this.loaderService.hide();

            try {
                this.availableBalance = data.available_balance;

                if (data.status) {
                    this.addresses = data.result;

                }

                if (this.prizesService.temporaryAddress.value) {
                    const temporaryAddress = this.prizesService.temporaryAddress.value;
                    this.addresses.push({address: temporaryAddress, id: 0});

                }

                if (this.route.snapshot.queryParams.addedNew) {
                    const checked = this.addresses[this.addresses.length-1].address;
                    this.sellForm.controls.address.setValue(checked);
                    this.checkedAddress = checked;
                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }
        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });

    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        let address = this.sellForm.controls.address.value;

        if (!this.addresses.length) {
            this.popupComponent.showPopupError({text: 'Please enter your mailing address'});
        } else {
            if(this.addresses.length == 1 && address === null){
                address = this.addresses[0].address;
                this.sellForm.controls.address.setValue(address);
                this.checkedAddress = address;
            }

            if (address !== null) {
                this.popupComponent.showPopupAgreement({text: `${address} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
                    if (status) {
                        this.submit();
                    }
                });
            } else {
                this.popupComponent.showPopupError({text: 'Please select the address to use'});
            }
        }
    }

    submit() {
        const address = this.sellForm.controls.address.value;
        const checkedAddress = this.addresses.filter((addressItem) => addressItem.address === address);
        const amount = this.sellForm.controls.amount.value;

        this.loaderService.show();
        this.prizesService.mailCheckCreatePayout(checkedAddress[0].id, address, amount).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.sellForm.reset();

                this.getPrizesInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }
}

