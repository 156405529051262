import { FUNDS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FundsService = /** @class */ (function () {
    function FundsService(http) {
        this.http = http;
        this.config = FUNDS_CONFIG;
    }
    FundsService.prototype.getPayments = function () {
        return this.http.post(this.config.payments, {});
    };
    FundsService.prototype.addFunds = function (id, method, amount) {
        return this.http.post(this.config.add, { id: id, method: method, amount: amount });
    };
    FundsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundsService_Factory() { return new FundsService(i0.ɵɵinject(i1.HttpClient)); }, token: FundsService, providedIn: "root" });
    return FundsService;
}());
export { FundsService };
