import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PRIZES_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PrizesService {
    private config = PRIZES_CONFIG;
    public temporaryEmail = new BehaviorSubject('');
    public temporaryAddress = new BehaviorSubject('');

    constructor(private http: HttpClient) {

    }

    getPrizesInfo() {
        return this.http.post<any>(this.config.data, {});
    }

    // getEmails() {
    //     return this.http.post<any>(this.config.emails, {});
    // }

    getPaypalEmails() {
        return this.http.post<any>(this.config.paypalEmails, {});
    }

    getCheckAddresses() {
        return this.http.post<any>(this.config.checkAddresses, {});
    }

    setTemporaryEmail(email) {
        this.temporaryEmail.next(email);
    }

    setTemporaryAddress(address) {
        this.temporaryAddress.next(address);
    }

    paypalCreatePayout(id, email, amount) {
        return this.http.post<any>(this.config.paypalCreatePayout, {id, email, amount});
    }

    mailCheckCreatePayout(id, address, amount) {
        return this.http.post<any>(this.config.mailCheckCreatePayout, {id, address, amount});
    }

    paypalAddEmail(email) {
        return this.http.post<any>(this.config.paypalAddEmail, {email});
    }

    checkAddAddress(address) {
        return this.http.post<any>(this.config.checkAddAddress, {address});
    }

}
