<div class="page page--primary rewards">
    <div class="page__inner">
        <h1 class="page__title">Contact Info</h1>

        <div class="page__form">
            <form [formGroup]="contactInfo" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="first_name"
                           placeholder="First Name"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="last_name"
                           placeholder="Last Name"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="email"
                           placeholder="Email"
                           [type]="'email'"
                           email="true"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="contactInfo.invalid">
                        SAVE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

