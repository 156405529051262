import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {PaypalService} from "../../../../service/settings/paypal.service";

@Component({
    selector: 'app-add-account',
    templateUrl: './add-account.component.html',
    styleUrls: ['./add-account.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class AddAccountComponent implements OnInit {
    newAccountForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private paypalService: PaypalService,
                private loaderService: LoaderService,
                private router: Router,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newAccountForm = this.formBuilder.group({
            email: [null, Validators.required],
        });
    }

    // onSubmit() {
    //     this.popupComponent.showPopupAgreement({text: `Add address ${address} to available addresses${saveText}?`}, (status) => {
    //         if (status) {
    //             this.submit();
    //         }
    //     });
    // }

    onSubmit() {
        const email = this.newAccountForm.controls.email.value;

            this.loaderService.show();
            this.paypalService.addAccount(email).subscribe(data => {
                this.loaderService.hide();

                try {
                    if(data.status){
                        this.popupComponent.showPopupSuccess({text:data.message});
                        this.newAccountForm.reset();
                        this.router.navigate(['/home/settings/paypal']);
                    } else {
                        this.popupComponent.showPopupError({text:data.message});
                    }
                } catch (e) {
                    this.loaderService.hide();
                    this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                }

            }, (error) => {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            });

    }
}
