import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {LoginService} from "../../service/login.service";

import {PopupComponent} from "../../shared/popup/popup.component";
import {MenuService} from "../../service/menu.service";
import {LoaderService} from "../../service/loader.service";
import {PHONE_MASK} from "../../config/country_phone_mask";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    pinForm: FormGroup;
    phoneForm: FormGroup;

    phoneFormActive = true;
    registrationPartActive = false;
    phoneLastFour = '1234';
    numOfDigits = 4;

    phoneMask;
    PHONE_MASK_CONFIG = PHONE_MASK;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private menuService: MenuService,
        private loaderService: LoaderService,
        private router: Router,
        private auth:AuthenticationService,
        private popupComponent: PopupComponent) {

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }
    ngOnInit() {
        this.auth.logout();

        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required],
            remember_user: [false],
            country: ['us']
        });

        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null))
        }

        //this.setPhoneMask(this.phoneForm.controls.country.value);

        if (localStorage.remember_user !== "false") {
            this.phoneForm.controls.remember_user.setValue(true);
            if(localStorage.country){
                this.phoneForm.controls.country.setValue(localStorage.country);
            }

            this.setPhoneMask(this.phoneForm.controls.country.value);

            this.phoneForm.controls.phone.setValue(localStorage.phone);

        } else {
            this.phoneForm.controls.remember_user.setValue(false);

            this.setPhoneMask(this.phoneForm.controls.country.value);

            this.phoneForm.controls.phone.setValue('');
            //this.phoneForm.controls.country.setValue('');
        }


        //this.setPhoneMask(this.phoneForm.controls.country.value);


    }


    resendSms(){
        this.onPhoneSubmit();
    }

    onPhoneSubmit(){
        if (this.phoneForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;
        let country = this.phoneForm.controls.country.value;

        this.loaderService.show();
        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    if (this.phoneForm.controls.remember_user.value) {
                        // save username and password
                        localStorage.phone = phone;
                        localStorage.country = country;
                    } else {
                        localStorage.phone = '';
                        localStorage.country = '';
                    }
                    localStorage.remember_user = this.phoneForm.controls.remember_user.value;

                    this.phoneLastFour = phone.substr(-4);

                    this.phoneFormActive = false;

                    let $this = this;
                    setTimeout(function(){
                        $this.inputs.toArray()[0].nativeElement.focus();
                    },0);
                }else{
                    if(data.code){
                        if(data.code == 402){
                            this.registrationPartActive = true;
                            this.phoneFormActive = false;
                        }else{
                            this.popupComponent.showPopupError({text:data.message});
                        }
                    }else{
                        this.popupComponent.showPopupError({text:data.message});
                    }


                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });

    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');
        let country = this.phoneForm.controls.country.value;

        this.loaderService.show();
        this.authenticationService.login(phone,pin,country).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.redirectToRewards(data.result);
                }else{
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    redirectToRewards(result){
        if (result.first_name == '' && result.last_name == '' && result.email == '') {
            this.router.navigate(['/home/rewards/form']);
        } else {
            this.router.navigate(['/home/rewards']);
        }
    }

    isPhoneClassActive(){
        if(this.phoneFormActive){
            return true;
        }

        return false;
    }

    isRegistrationClassActive(){
        if(this.registrationPartActive){
            return true;
        }

        return false;
    }

    isPinClassActive(){
        if(!this.phoneFormActive){
            return true;
        }

        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    hideLogin() {
        this.loginService.hide();
        document.body.classList.remove('is-hidden');
    }

    onRememberUser(e) {
        const checked = e.target.checked;
        this.phoneForm.controls.remember_user.setValue(!!checked);
    }

    onRegisterNewUser() {
        let phone = this.phoneForm.controls.phone.value;
        let country = this.phoneForm.controls.country.value;

        this.loaderService.show();
        this.authenticationService.register(phone, country).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    if (this.phoneForm.controls.remember_user.value) {
                        localStorage.phone = phone;
                        localStorage.country = country;
                    } else {
                        localStorage.phone = '';
                        localStorage.country = '';
                    }
                    localStorage.remember_user = this.phoneForm.controls.remember_user.value;

                    this.authenticationService.setData(data.result);

                    this.redirectToRewards(data.result);

                    // this.phoneLastFour = phone.substr(-4);
                    //
                    // this.phoneFormActive = false;
                    // this.registrationPartActive = false;
                    //
                    // let $this = this;
                    // setTimeout(function(){
                    //     $this.inputs.toArray()[0].nativeElement.focus();
                    // },0);
                }else{
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    setPhoneMask(country) {
        this.phoneMask = this.PHONE_MASK_CONFIG[country];
    }

    onCountryChange(e) {
        const value = e.target.value;
        this.setPhoneMask(value);
    }
}

