<div class="page page--secondary" *ngIf="prizesInfo">
    <div class="page__inner">
<!--        <a [routerLink]="['/home/prizes']" class="page__back">-->
<!--            <span class="visually-hidden">Back</span>-->
<!--        </a>-->
        <h1 class="page__title">PRIZES</h1>
        <div class="page__head">
            <div class="balance">
                <h2 class="balance__title">AVAILABLE BALANCE</h2>
                <div class="balance__amount">{{prizesInfo.totalDebit | currency : 'USD' : 'symbol'}}</div>
            </div>
        </div>
        <div class="page__form">
            <div class="navigation">
                <a [routerLink]="['/home/prizes/paypal']" class="navigation__button button snake-btn is-notouched">
                    PAYPAL
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/prizes/mail-check']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    MAIL CHECK
                    <svg><rect></rect></svg>
                </a>
            </div>
        </div>
        <div class="page__body">

            <div class="dropdown is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        PRIZES SENT
                    </div>
                    <div class="dropdown__total">
                        {{prizesInfo.totalCredit | currency : 'USD' : 'symbol' : '1.0-0'}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body">
                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__type">TYPE</th>
                            <th class="transactions__date">DATE</th>
                            <th class="transactions__amount">AMOUNT</th>
                        </tr>
                        <tr *ngFor="let action of prizesInfo.credit" class="transactions__item">
                            <td class="transactions__type">
                                <svg width="18px" height="14px">
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayment(action.type)"></use>
                                </svg>
                            </td>
                            <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                            <td class="transactions__amount">{{action.amount | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

