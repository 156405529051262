import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-new-email',
    templateUrl: './new-email.component.html',
    styleUrls: ['./new-email.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewEmailComponent implements OnInit {
    newEmailForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private prizesService: PrizesService,
                private loaderService: LoaderService,
                private router: Router,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newEmailForm = this.formBuilder.group({
            email: [null, Validators.required],
            save: [true]
        });
    }

    onSubmit() {
        const email = this.newEmailForm.controls.email.value;
        const save = this.newEmailForm.controls.save.value;
        const saveText = save ? ' and save it for future use' : '';

        this.popupComponent.showPopupAgreement({text: `Add email ${email} to available emails${saveText}?`}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    submit() {
        const email = this.newEmailForm.controls.email.value;
        const save = this.newEmailForm.controls.save.value;

        if (save) {
            this.loaderService.show();
            this.prizesService.paypalAddEmail(email).subscribe(data => {
                this.loaderService.hide();
                try {
                    if(data.status){
                        this.popupComponent.showPopupSuccess({text:data.message});
                        this.newEmailForm.reset();
                        this.router.navigate(['/home/prizes/paypal'], { queryParams: { addedNew: true } });
                    } else {
                        this.popupComponent.showPopupError({text:data.message});
                    }
                } catch (e) {

                }

            }, (error) => {
                this.loaderService.hide();
            });
        } else {
            this.prizesService.setTemporaryEmail(email);
            this.newEmailForm.reset();
            this.router.navigate(['/home/prizes/paypal'], { queryParams: { addedNew: true } });
        }

    }

    onSaveEmail(e) {
        const checked = e.target.checked;
        this.newEmailForm.controls.save.setValue(!!checked);
    }

}
