<div class="page page--primary rewards">
    <div class="page__inner">
        <h1 class="page__title">REWARDS</h1>
        <div class="rewards__btn">
            <button (click)="onSweeps()" class="button snake-btn is-notouched">
                SWEEPS
                <svg><rect></rect></svg>
            </button>
        </div>
        <div *ngIf="userPin" class="rewards__pin">
            <div class="rewards__code">{{userPin}}</div>
            <button data-clipboard-action="copy" (click)="copyToBuffer()" title="copy number" class="rewards__save">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M 6 2 L 6 4 L 20 4 L 20 18 L 22 18 L 22 4 C 22 2.895 21.105 2 20 2 L 6 2 z M 4 6 C 2.895 6 2 6.895 2 8 L 2 20 C 2 21.105 2.895 22 4 22 L 16 22 C 17.105 22 18 21.105 18 20 L 18 8 C 18 6.895 17.105 6 16 6 L 4 6 z M 4 8 L 16 8 L 16 20 L 4 20 L 4 8 z M 9 10 L 9 13 L 6 13 L 6 15 L 9 15 L 9 18 L 11 18 L 11 15 L 14 15 L 14 13 L 11 13 L 11 10 L 9 10 z"></path>
                </svg>
            </button>
        </div>
        <div class="page__body">
            <div class="images">
                <img class="images__item" src="../../../assets/images/rewards/rewards-img-1.png" alt="">
                <img class="images__item" src="../../../assets/images/rewards/rewards-img-2.png" alt="">
            </div>
        </div>
    </div>
</div>

