import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CONTACT_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class ContactInfoService {
    private config = USER_CONTACT_CONFIG;

    constructor(private http: HttpClient) {

    }

    getUserContacts() {
        return this.http.post<any>(this.config.info, {});
    }

    saveUserContacts(first_name, last_name, email) {
        return this.http.post<any>(this.config.save, {first_name, last_name, email});
    }
}
