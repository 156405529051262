import { PAYPAL_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaypalService = /** @class */ (function () {
    function PaypalService(http) {
        this.http = http;
        this.config = PAYPAL_CONFIG;
    }
    PaypalService.prototype.getAccounts = function () {
        return this.http.post(this.config.accounts, {});
    };
    PaypalService.prototype.deleteAccount = function (id) {
        return this.http.post(this.config.delete, { id: id });
    };
    PaypalService.prototype.addAccount = function (email) {
        return this.http.post(this.config.add, { email: email });
    };
    PaypalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaypalService_Factory() { return new PaypalService(i0.ɵɵinject(i1.HttpClient)); }, token: PaypalService, providedIn: "root" });
    return PaypalService;
}());
export { PaypalService };
