export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
    transactionsTypeDebit: '1',
    transactionsTypeCredit: '2',
    postPaymentTypeFree: 1,
    postPaymentTypePay: 0,
    paymentMethodCard: 1,
    paymentMethodPaypal: 2,
};

