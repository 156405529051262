import { ADDRESSES_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AddressesService = /** @class */ (function () {
    function AddressesService(http) {
        this.http = http;
        this.config = ADDRESSES_CONFIG;
    }
    AddressesService.prototype.getAddresses = function () {
        return this.http.post(this.config.addresses, {});
    };
    AddressesService.prototype.deleteAddress = function (id) {
        return this.http.post(this.config.delete, { id: id });
    };
    AddressesService.prototype.addAddress = function (address) {
        return this.http.post(this.config.add, { address: address });
    };
    AddressesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddressesService_Factory() { return new AddressesService(i0.ɵɵinject(i1.HttpClient)); }, token: AddressesService, providedIn: "root" });
    return AddressesService;
}());
export { AddressesService };
