import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PAYMENT_TYPES} from "../../config/payment_types";
import {PopupComponent} from "../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../service/loader.service";
import {PrizesService} from "../../service/prizes/prizes.service";

@Component({
    selector: 'app-prizes',
    templateUrl: './prizes.component.html',
    styleUrls: ['./prizes.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PrizesComponent implements OnInit {
    prizesInfo = {
        totalDebit: 0,
        totalCredit: 0,
        credit: []
    };

    PAYMENT_TYPES = PAYMENT_TYPES;
    menu = false;

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService) {

    }


    ngOnInit() {
        this.getPrizesInfo();
    }

    getPrizesInfo(){
        this.loaderService.show();

        this.prizesService.getPrizesInfo().subscribe(data => {
            this.loaderService.hide();

            try {
                if (data.status) {
                    this.prizesInfo = data;
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });

    }

    getPayment(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => +this.PAYMENT_TYPES[key] === +id);
        return type;
    }

    toggleDropdown(e) {
        if (e.target.offsetParent.classList.contains('is-hidden')) {
            e.target.offsetParent.classList.remove('is-hidden');
        } else {
            e.target.offsetParent.classList.add('is-hidden');
        }
    }

    getDate(date) {
        return date.replace(/\s/g, 'T');
    }
}

