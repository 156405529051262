import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {RewardsComponent} from "./rewards/rewards.component";
import {PrizesComponent} from "./prizes/prizes.component";
import {PaypalComponent} from "./prizes/paypal/paypal.component";
import {MailCheckComponent} from "./prizes/mail-check/mail-check.component";
import {NewEmailComponent} from "./prizes/paypal/new-email/new-email.component";
import {NewAddressComponent} from "./prizes/mail-check/new-address/new-address.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {PaypalSettingsComponent} from "./settings/paypal-settings/paypal-settings.component";
import {AddAccountComponent} from "./settings/paypal-settings/add-account/add-account.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'paypal/execute/success', component: PaypalPaymentsSuccessComponent},
            {path: 'paypal/execute/unsuccess', component: PaypalPaymentsUnsuccessComponent},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children: [
                    {path: 'rewards', component: RewardsComponent},
                    {path: 'rewards/form', component: RewardsFormComponent},
                    {path: 'prizes', component: PrizesComponent},
                    {path: 'investments', component: InvestmentsComponent},
                    {path: 'prizes/paypal', component: PaypalComponent},
                    {path: 'prizes/paypal/new-email', component: NewEmailComponent},
                    {path: 'prizes/mail-check', component: MailCheckComponent},
                    {path: 'prizes/mail-check/new-address', component: NewAddressComponent},
                    {path: 'settings', component: SettingsComponent},
                    {path: 'settings/card', component: CardComponent},
                    {path: 'settings/card/new-card', component: NewCardComponent},
                    {path: 'settings/card/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/card/edit/:id', component: EditCardComponent},
                    {path: 'settings/card/verify/:id', component: VerifyCardComponent},
                    {path: 'settings/paypal', component: PaypalSettingsComponent},
                    {path: 'settings/paypal/add', component: AddAccountComponent},
                    {path: 'settings/addresses', component: AddressesSettingsComponent},
                    {path: 'settings/addresses/add', component: AddAddressComponent},
                    {path: 'settings/funds', component: FundsComponent},
                    {path: 'settings/contacts', component: ContactInfoComponent},
                ]},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
