import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactInfoService} from "../../../service/contactInfo.service";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class ContactInfoComponent implements OnInit {
    contactInfo: FormGroup;
    info;
    constructor(private popupComponent: PopupComponent,
                private contactInfoService: ContactInfoService,
                private router: Router,
                private loaderService: LoaderService,
                private formBuilder: FormBuilder) {

    }


    ngOnInit() {
        this.contactInfo = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            email: [null, Validators.required],
        });

        this.loaderService.show();
        this.contactInfoService.getUserContacts().subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.info = data.result;
                    this.contactInfo.controls.first_name.setValue(this.info.first_name);
                    this.contactInfo.controls.last_name.setValue(this.info.last_name);
                    this.contactInfo.controls.email.setValue(this.info.email);
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

    onSubmit() {
        const firstName = this.contactInfo.controls.first_name.value;
        const lastName = this.contactInfo.controls.last_name.value;
        const email = this.contactInfo.controls.email.value;

        this.loaderService.show();
        this.contactInfoService.saveUserContacts(firstName, lastName, email).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){

                    this.popupComponent.showPopupSuccess({text: data.message});

                    this.router.navigate(['/home/settings']);
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }
}
