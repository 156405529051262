
<div class="page page--quaternary" *ngIf="card">
    <div class="page__inner">
        <a [routerLink]="['/home/settings/card/card-menu/'+cardId]" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">Edit CARD</h1>
        <div class="page__form">
            <form [formGroup]="newCardForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input mask="0000 0000 0000 0000"
                           formControlName="card_number"
                           placeholder="{{cardPlaceholder}}"
                           [type]="'tel'"
                           disabled
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__row">
                    <div class="form-simple__item">
                        <input formControlName="exp"
                               mask="00/00"
                               placeholder="Exp MM/YY"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>
                    <div class="form-simple__item">
                        <input formControlName="cvv"
                               mask="000"
                               placeholder="***"
                               disabled
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>
                </div>

                <div class="form-simple__item">
                    <input formControlName="card_nickname"
                           placeholder="Card Nickname"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="newCardForm.invalid">
                        SAVE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

