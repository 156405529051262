<div class="login">
    <div class="login__inner">
        <div class="login__header">
            <button *ngIf="isPinClassActive()" (click)="phoneFormActive = true; registrationPartActive = false" class="login__back">
                <span class="visually-hidden">Back</span>
            </button>
            <img class="login__logo" src="../../../../assets/images/activate_yourself.png" alt="">

        </div>
        <div *ngIf="!isRegistrationClassActive()" class="login__title">eBox Member Portal</div>

        <div *ngIf="isRegistrationClassActive()" class="login__text login__text--mb-sm">
            Hi there! Thank you for choosing eBox. You are registering with phone number:
            <div class="accent">{{phoneForm.controls.phone.value | mask: phoneMask}}</div>
        </div>
        <div *ngIf="isRegistrationClassActive()" class="login__text">
            Please click the Register button below to proceed<br/>
            or click<br/>
            The back arrow to correct your phone number

        </div>

        <div class="login__forms">
            <div *ngIf="isPhoneClassActive()" class="text-center login-tab">
                <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="login__form">
                    <ul class="login__countries countries">
                        <li class="countries__item">
                            <input  formControlName="country"
                                    id="us"
                                    class="countries__input"
                                    type="radio"
                                    value="us"
                                    name="country"
                                    checked
                                    (change)="onCountryChange($event)">

                            <label for="us" class="countries__label">
                                <img class="countries__img" src="../../../../assets/images/flags/flag-us.jpg" alt="US">
                                <span class="countries__name">US</span>
                            </label>
                        </li>
                        <li class="countries__item">
                            <input  formControlName="country"
                                    id="mx"
                                    class="countries__input"
                                    type="radio"
                                    value="mx"
                                    name="country"
                                    (change)="onCountryChange($event)">

                            <label for="mx" class="countries__label">
                                <img class="countries__img" src="../../../../assets/images/flags/flag-mx.jpg" alt="MX">
                                <span class="countries__name">MX</span>
                            </label>
                        </li>
                        <li class="countries__item">
                            <input  formControlName="country"
                                    id="au"
                                    class="countries__input"
                                    type="radio"
                                    value="au"
                                    name="country"
                                    (change)="onCountryChange($event)">

                            <label for="au" class="countries__label">
                                <img class="countries__img" src="../../../../assets/images/flags/flag-au.jpg" alt="AU">
                                <span class="countries__name">AU</span>
                            </label>
                        </li>
                    </ul>

                    <div class="login__item">
                        <input [mask]="phoneMask"
                               formControlName="phone"
                               placeholder="Phone Number"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">
                    </div>

                    <div class="login__item">

                        <input formControlName="remember_user"
                               id="remember_user"
                               [type]="'checkbox'"
                               class="login-checkbox"
                               (change)="onRememberUser($event)"
                               [checked]="!!phoneForm.controls.remember_user.value"
                               autocomplete="off">
                        <label for="remember_user">Remember Username</label>
                    </div>
                    <div class="form-btn-submit">
                        <button class="button snake-btn is-notouched" [disabled]="!phoneForm.valid">
                            Login
                            <svg><rect></rect></svg>
                        </button>

                    </div>
                </form>
            </div>

            <div *ngIf="isPinClassActive() && !isRegistrationClassActive()" class="login-tab">
                <div class="login-content">
                    <p class="text t-light login__text">Enter the four digit pin sent to the number ending in -{{phoneLastFour}}.</p>
                </div>
                <form [formGroup]="pinForm" (ngSubmit)="onSubmit()" class="login-page__form">
                    <div class="login__items" formArrayName="digits">
                        <input *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"
                               mask="0"
                               class="login-input-small" type="number">
                    </div>

                    <button (click)="resendSms()" type="button" class="text t-light login-text login__resend">Resend</button>

                    <div class="text-center form-btn-submit">
                        <button class="button snake-btn is-notouched" [disabled]="!pinForm.valid">
                            VERIFY
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </form>
            </div>

            <div *ngIf="isRegistrationClassActive()" class="login-tab">
                <div class="text-center form-btn-submit">
                    <button class="button snake-btn is-notouched" (click)="onRegisterNewUser()">
                        Register
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
