<div class="page page--quaternary" [class.page--blured]="menu">
    <div class="page__inner">
<!--        <a [routerLink]="['/home/sell/existing-user']" class="page__back">-->
<!--            <span class="visually-hidden">Back</span>-->
<!--        </a>-->
        <h1 class="page__title">SETTINGS</h1>
        <div class="page__head">
            <div class="page__left">
                <div *ngIf="userStatus === 'active'" class="page__status">ACCOUNT ACTIVE</div>
                <div *ngIf="userStatus !== 'active'" class="page__status">ACCOUNT FROZEN</div>
            </div>
            <div class="page__right">
                <button (click)="menu = true" class="page__options">
                    <svg viewBox="0 0 29 7" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                    </svg>
                </button>
            </div>
            <div *ngIf="userStatus === 'frozen'" class="page__status-info">User Frozen</div>
            <div *ngIf="userStatus === 'blocked'" class="page__status-info">Admin Frozen</div>
        </div>

        <div class="page__body">

            <div class="navigation">
                <a [routerLink]="['/home/settings/card']" class="navigation__button button snake-btn is-notouched">
                    CREDIT/DEBIT CARD
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/settings/paypal']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    PAYPAL
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/settings/addresses']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    ADDRESSES
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/settings/contacts']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    Contact info
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/settings/funds']" class="navigation__button navigation__button--rounded is-notouched">
                    <span class="navigation__button-text">ADD <br>FUNDS</span>
                    <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke="#DCBCFF" stroke-width="2"/>
                    </svg>
                </a>
            </div>

        </div>
    </div>
</div>
<div class="addition-menu" [class.is-active]="menu">
    <ul class="addition-menu__list">
        <li class="addition-menu__item">
            <button *ngIf="userStatus === 'active'" (click)="askFreezeUser()">Freeze Account</button>
            <button *ngIf="userStatus !== 'active'" (click)="askUnFreezeUser()">Unfreeze Account</button>
        </li>
        <li class="addition-menu__item">
            <button (click)="askDeleteUser()">Delete</button>
        </li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
</div>
