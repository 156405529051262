/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./settings.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../service/loader.service";
import * as i7 from "../../service/userStatus.service";
import * as i8 from "../../shared/popup/popup.component";
import * as i9 from "../../service/sell/existingUser.service";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
function View_SettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page__status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ACCOUNT ACTIVE"]))], null, null); }
function View_SettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page__status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ACCOUNT FROZEN"]))], null, null); }
function View_SettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page__status-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User Frozen"]))], null, null); }
function View_SettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page__status-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Admin Frozen"]))], null, null); }
function View_SettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askFreezeUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Freeze Account"]))], null, null); }
function View_SettingsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askUnFreezeUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Unfreeze Account"]))], null, null); }
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 52, "div", [["class", "page page--quaternary"]], [[2, "page--blured", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 51, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SETTINGS"])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "page__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "page__left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "page__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "page__options"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.menu = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, ":svg:svg", [["viewBox", "0 0 29 7"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#menu-dots"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_4)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 34, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 33, "div", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "a", [["class", "navigation__button button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), (_l()(), i1.ɵted(-1, null, [" CREDIT/DEBIT CARD "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 5, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(28, 1), (_l()(), i1.ɵted(-1, null, [" PAYPAL "])), (_l()(), i1.ɵeld(30, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 5, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 33).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(34, 1), (_l()(), i1.ɵted(-1, null, [" ADDRESSES "])), (_l()(), i1.ɵeld(36, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 5, "a", [["class", "navigation__button navigation__button--secondary button snake-btn is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 39).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(39, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(40, 1), (_l()(), i1.ɵted(-1, null, [" Contact info "])), (_l()(), i1.ɵeld(42, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 8, "a", [["class", "navigation__button navigation__button--rounded is-notouched"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 45).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(45, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(46, 1), (_l()(), i1.ɵeld(47, 0, null, null, 3, "span", [["class", "navigation__button-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ADD "])), (_l()(), i1.ɵeld(49, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FUNDS"])), (_l()(), i1.ɵeld(51, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "86"], ["viewBox", "0 0 86 86"], ["width", "86"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 0, ":svg:path", [["d", "M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542"], ["stroke", "#DCBCFF"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 11, "div", [["class", "addition-menu"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(54, 0, null, null, 8, "ul", [["class", "addition-menu__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(55, 0, null, null, 4, "li", [["class", "addition-menu__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_5)), i1.ɵdid(57, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_6)), i1.ɵdid(59, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(60, 0, null, null, 2, "li", [["class", "addition-menu__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(61, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askDeleteUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(63, 0, null, null, 1, "button", [["class", "addition-menu__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.menu = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.userStatus === "active"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.userStatus !== "active"); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.userStatus === "frozen"); _ck(_v, 15, 0, currVal_3); var currVal_4 = (_co.userStatus === "blocked"); _ck(_v, 17, 0, currVal_4); var currVal_7 = _ck(_v, 22, 0, "/home/settings/card"); _ck(_v, 21, 0, currVal_7); var currVal_10 = _ck(_v, 28, 0, "/home/settings/paypal"); _ck(_v, 27, 0, currVal_10); var currVal_13 = _ck(_v, 34, 0, "/home/settings/addresses"); _ck(_v, 33, 0, currVal_13); var currVal_16 = _ck(_v, 40, 0, "/home/settings/contacts"); _ck(_v, 39, 0, currVal_16); var currVal_19 = _ck(_v, 46, 0, "/home/settings/funds"); _ck(_v, 45, 0, currVal_19); var currVal_21 = (_co.userStatus === "active"); _ck(_v, 57, 0, currVal_21); var currVal_22 = (_co.userStatus !== "active"); _ck(_v, 59, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 0, 0, currVal_0); var currVal_5 = i1.ɵnov(_v, 21).target; var currVal_6 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 27).target; var currVal_9 = i1.ɵnov(_v, 27).href; _ck(_v, 26, 0, currVal_8, currVal_9); var currVal_11 = i1.ɵnov(_v, 33).target; var currVal_12 = i1.ɵnov(_v, 33).href; _ck(_v, 32, 0, currVal_11, currVal_12); var currVal_14 = i1.ɵnov(_v, 39).target; var currVal_15 = i1.ɵnov(_v, 39).href; _ck(_v, 38, 0, currVal_14, currVal_15); var currVal_17 = i1.ɵnov(_v, 45).target; var currVal_18 = i1.ɵnov(_v, 45).href; _ck(_v, 44, 0, currVal_17, currVal_18); var currVal_20 = _co.menu; _ck(_v, 53, 0, currVal_20); }); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], [[40, "@slideToRight", 0], [2, "is-menu", null]], null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i4.SettingsComponent, [i5.FormBuilder, i3.ActivatedRoute, i1.ElementRef, i6.LoaderService, i7.UserStatusService, i8.PopupComponent, i9.ExistingUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = i1.ɵnov(_v, 1).menu; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i4.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
