<div class="page page--secondary">
    <div class="page__inner">
        <a [routerLink]="['/home/prizes']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">MAIL CHECK</h1>
        <div class="page__head">
            <div class="balance">
                <h2 class="balance__title">AVAILABLE BALANCE</h2>
                <div class="balance__amount">{{availableBalance | currency : 'USD' : 'symbol'}}</div>
            </div>
        </div>

        <div class="page__form">
            <form [formGroup]="sellForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">

                <div class="saved">
                    <div class="saved__title">SAVED ADDRESS</div>
                    <div *ngFor="let address of addresses" class="form-simple__item">
                        <input formControlName="address"
                               [id]="'email-'+address.id"
                               [type]="'radio'"
                               class="form-simple__radio"
                               [value]="address.address"
                               [attr.data-id]="address.id"
                               [checked]="checkedAddress === address.address"
                               name="email"
                               autocomplete="off">
                        <label [for]="'email-'+address.id">{{address.address}}</label>
                    </div>

                    <div class="form-simple__btns">
                        <a [routerLink]="['/home/prizes/mail-check/new-address']" class="button snake-btn is-notouched">
                            NEW ADDRESS
                            <svg><rect></rect></svg>
                        </a>
                    </div>
                </div>



                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="!+sellForm.controls.amount.value">
                        REQUEST
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

