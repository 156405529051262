import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class UserStatusService {
    private config = USER_CONFIG;

    constructor(private http: HttpClient) {

    }

    getUserStatus() {
        return this.http.post<any>(this.config.status, {});
    }

    freeze() {
        return this.http.post<any>(this.config.freeze, {});
    }

    activate() {
        return this.http.post<any>(this.config.activate, {});
    }

    delete() {
        return this.http.post<any>(this.config.delete, {});
    }
}
