import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";

@Component({
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalComponent implements OnInit {
    sellForm: FormGroup;
    emails = [];
    checkedEmail = null;
    availableBalance = 0;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private existingUserService: ExistingUserService) {

    }

    ngOnInit() {
        this.getPrizesInfo();

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
            email: [null, Validators.required],
        });
    }

    getPrizesInfo(){
        this.loaderService.show();

        this.prizesService.getPaypalEmails().subscribe(data => {
            this.loaderService.hide();
            try {
                this.availableBalance = data.available_balance;

                if (data.status) {
                    this.emails = data.result;
                    if (this.prizesService.temporaryEmail.value) {
                        const temporaryEmail = this.prizesService.temporaryEmail.value;
                        this.emails.push({email: temporaryEmail, id: 0})
                        this.sellForm.controls.email.setValue(temporaryEmail);
                        this.checkedEmail = temporaryEmail;
                    }
                    if (this.route.snapshot.queryParams.addedNew) {
                        const checked = this.emails[this.emails.length-1].email;
                        this.sellForm.controls.email.setValue(checked);
                        this.checkedEmail = checked;
                    }
                }
            } catch (e) {

            }
        }, (error) => {
            this.loaderService.hide();

        });

    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        let email = this.sellForm.controls.email.value;

        if (!this.emails.length) {
            this.popupComponent.showPopupError({text: 'Please enter your PayPal email'});
        } else {
            if (email === null && this.emails.length === 1) {
                email = this.emails[0].email;
                this.sellForm.controls.email.setValue(email);
                this.checkedEmail = email;
            }

            if (email !== null) {
                this.popupComponent.showPopupAgreement({text: `${email} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
                    if (status) {
                        this.submit();
                    }
                });
            } else {
                this.popupComponent.showPopupError({text: 'Please select the email to use'});
            }
        }
    }

    submit() {
        const email = this.sellForm.controls.email.value;
        const checkedEmail = this.emails.filter((emailItem) => emailItem.email === email);
        const amount = this.sellForm.controls.amount.value;

        this.loaderService.show();
        this.prizesService.paypalCreatePayout(checkedEmail[0].id, email, amount).subscribe(data => {
            this.loaderService.hide();
            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.sellForm.reset();

                    this.getPrizesInfo();
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }
        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }
}

