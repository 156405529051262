<div class="page page--quaternary" *ngIf="!frame">
    <div class="page__inner">
        <a [routerLink]="['/home/settings']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">ADD FUNDS</h1>

        <div class="page__form">
            <form [formGroup]="sellForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">

                <div class="saved">
                    <div class="saved__title">SAVED PAYMENT METHODS</div>
                    <div *ngFor="let method of methods" class="form-simple__item">
                        <input *ngIf="isCard(method.payment_method)" formControlName="method"
                               [id]="'card-'+method.payment_method+method.id"
                               [type]="'radio'"
                               class="form-simple__radio"
                               [value]="method"
                               [attr.data-id]="method.id"
                               name="email"
                               (change)="changeMethod($event,method)"
                               autocomplete="off">

                        <label *ngIf="isCard(method.payment_method)" [for]="'card-'+method.payment_method+method.id">{{getPaymentName(method)}}</label>

                        <input *ngIf="isPaypal(method.payment_method)" formControlName="method"
                               [id]="'email-'+method.payment_method+method.id"
                               [type]="'radio'"
                               class="form-simple__radio"
                               [value]="method"
                               [attr.data-id]="method.id"
                               name="email"
                               [checked]="activeMethod === method"
                               (change)="changeMethod($event, method)"
                               autocomplete="off">
                        <label *ngIf="isPaypal(method.payment_method)" [for]="'email-'+method.payment_method+method.id">{{getPaymentName(method)}}</label>
                    </div>

                </div>



                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="!+sellForm.controls.amount.value">
                        Add
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


<div *ngIf="frame" class="frame">
    <iframe [src]="frameUrl" #iframe></iframe>
</div>

<button *ngIf="frame" (click)="closeFrame()" class="close">
    <span class="visually-hidden">Close</span>
    <svg class="close__icon" width="34" height="34">
        <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
    </svg>
</button>
