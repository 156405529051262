import { BehaviorSubject } from 'rxjs';
import { PRIZES_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PrizesService = /** @class */ (function () {
    function PrizesService(http) {
        this.http = http;
        this.config = PRIZES_CONFIG;
        this.temporaryEmail = new BehaviorSubject('');
        this.temporaryAddress = new BehaviorSubject('');
    }
    PrizesService.prototype.getPrizesInfo = function () {
        return this.http.post(this.config.data, {});
    };
    // getEmails() {
    //     return this.http.post<any>(this.config.emails, {});
    // }
    PrizesService.prototype.getPaypalEmails = function () {
        return this.http.post(this.config.paypalEmails, {});
    };
    PrizesService.prototype.getCheckAddresses = function () {
        return this.http.post(this.config.checkAddresses, {});
    };
    PrizesService.prototype.setTemporaryEmail = function (email) {
        this.temporaryEmail.next(email);
    };
    PrizesService.prototype.setTemporaryAddress = function (address) {
        this.temporaryAddress.next(address);
    };
    PrizesService.prototype.paypalCreatePayout = function (id, email, amount) {
        return this.http.post(this.config.paypalCreatePayout, { id: id, email: email, amount: amount });
    };
    PrizesService.prototype.mailCheckCreatePayout = function (id, address, amount) {
        return this.http.post(this.config.mailCheckCreatePayout, { id: id, address: address, amount: amount });
    };
    PrizesService.prototype.paypalAddEmail = function (email) {
        return this.http.post(this.config.paypalAddEmail, { email: email });
    };
    PrizesService.prototype.checkAddAddress = function (address) {
        return this.http.post(this.config.checkAddAddress, { address: address });
    };
    PrizesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrizesService_Factory() { return new PrizesService(i0.ɵɵinject(i1.HttpClient)); }, token: PrizesService, providedIn: "root" });
    return PrizesService;
}());
export { PrizesService };
