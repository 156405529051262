import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {UserCardService} from "../../../service/settings/userCard.service";
import {USER_STATUS} from "../../../config/user_status";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {PaypalService} from "../../../service/settings/paypal.service";

@Component({
    selector: 'app-paypal-settings',
    templateUrl: './paypal-settings.component.html',
    styleUrls: ['./paypal-settings.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalSettingsComponent implements OnInit {
    accounts;
    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private paypalService: PaypalService) {

    }


    ngOnInit() {
      this.getAccountsList();
    }

    getAccountsList() {
        this.loaderService.show();
        this.paypalService.getAccounts().subscribe(data => {
            this.loaderService.hide();

            try {
                this.accounts = [];
                if(data.status){
                    this.accounts = data.result;
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }



        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

    askDelete(account) {
        this.popupComponent.showPopupAgreement({text: `<b>Delete PayPal Account:</b> </br></br> ${account.email}`}, (status) => {
            if (status) {
                this.delete(account.id);
            }
        });
    }

    delete(id) {
        this.paypalService.deleteAccount(id).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.getAccountsList();
                }  else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

}
