<div class="child" [class.child--blured]="menu">
    <router-outlet></router-outlet>
</div>

<div class="menu">
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/rewards']">
        <svg width="35" height="30">
            <use xlink:href="../../../../assets/images/sprite.svg#home"></use>
        </svg>
    </a>
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/prizes']">
        <svg width="29" height="33">
            <use xlink:href="../../../../assets/images/sprite.svg#prizes"></use>
        </svg>
    </a>
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/investments']">
        <svg width="34" height="30">
            <use xlink:href="../../../../assets/images/sprite.svg#investment"></use>
        </svg>
    </a>
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/settings']">
        <svg width="30" height="30">
            <use xlink:href="../../../../assets/images/sprite.svg#settings"></use>
        </svg>
    </a>
    <button (click)="menu = true" class="menu__btn">
        <svg width="29" height="7">
            <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
        </svg>
    </button>
</div>
<div class="addition-menu" [class.is-active]="menu">
    <ul class="addition-menu__list">
        <li class="addition-menu__item">Terms and Conditions</li>
        <li (click)="logout()" class="addition-menu__item">Logout</li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
</div>
