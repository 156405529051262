<div class="page page--secondary">
    <div class="page__inner">
        <a [routerLink]="['/home/prizes/paypal']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">New EMAIL</h1>
        <div class="page__form">
            <form [formGroup]="newEmailForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="email"
                           placeholder="Email"
                           [type]="'email'"
                           email="true"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input formControlName="save"
                           id="save"
                           [type]="'checkbox'"
                           (change)="onSaveEmail($event)"
                           [checked]="!!newEmailForm.controls.save.value"
                           class="form-simple__radio"
                           autocomplete="off">
                    <label for="save">Save Email for Future Use</label>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="newEmailForm.invalid">
                        CONFIRM
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

