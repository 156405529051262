import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../modules/auth/_services/authentication.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LoginService} from "../../../../service/login.service";

import {PopupComponent} from "../../../../shared/popup/popup.component";
import {MenuService} from "../../../../service/menu.service";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {LoaderService} from "../../../../service/loader.service";
import {formatCurrency} from "@angular/common";


@Component({
    selector: 'app-verify-card',
    templateUrl: './verify-card.component.html',
    styleUrls: ['./verify-card.component.scss'],
})
export class VerifyCardComponent implements OnInit {
    pinForm: FormGroup;
    numOfDigits = 4;
    card;
    cardId;
    infoPopupShown = false;
    @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    @ViewChild('infoPopup', {static: false}) infoPopup: ElementRef;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (!(<HTMLElement>this.infoPopup.nativeElement).contains(event.target)) {
            this.infoPopupShown = false;
        };
    }

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private menuService: MenuService,
        private router: Router,
        private userCardService: UserCardService,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        private auth:AuthenticationService,
        private popupComponent: PopupComponent) {

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }
    ngOnInit() {

        for (let i = 0; i< this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null))
        }

        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo();
        });

    }

    resendCode(){
        this.loaderService.show();
        this.userCardService.resendCode(this.cardId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                }else{
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }



        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    getCardInfo() {
        this.loaderService.show();
        this.userCardService.checkVerify(this.cardId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.card = data.result;
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }



        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        this.userCardService.verifyCard(pin, this.cardId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.pinForm.reset();
                    this.router.navigate(['/home/settings/card']);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    getCardName() {
        return this.card.description;
    }

    getVerifyCode() {
        return this.card.verify_code;
    }

    getTransactionPrice() {
        const price = this.card.transaction_price ? this.card.transaction_price : 0.5;
        return formatCurrency(price, 'en','$')
    }

}

