<div class="page page--quaternary login-page" *ngIf="card">
    <div class="page__inner">
        <a [routerLink]="['/home/settings/card/card-menu/'+cardId]" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">VERIFY CARD</h1>
        <div class="login-content">
            <p class="text t-light login__text">Enter the four digit code found in your online statement for the card ending in -{{card.last_four}}</p>
        </div>
        <div class="page__form">

            <form [formGroup]="pinForm" (ngSubmit)="onSubmit()" class="login-page__form">
                <div class="login__items" formArrayName="digits">
                    <input required *ngFor="let field of pinForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i" (keydown)="check(i, field, $event)"
                           mask="0"
                           class="login-input-small" type="number">
                </div>

                <div class="login__items text-center form-btn-submit">
                    <button (click)="resendCode()" type="button" class="text t-light login-text login__resend">Resend</button>
                </div>

                <div class="text-center form-btn-submit">
                    <button class="button snake-btn is-notouched" [disabled]="!pinForm.valid">
                        VERIFY
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

        <div #infoPopup class="info-popup">
            <div class="info-popup__wrapper" [class.is-active]="infoPopupShown">
                <div class="info-popup__inner">
                    <div class="info-popup__head">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.26316 5.57143V9.90476H3.15789V5.57143H1.26316ZM5.05263 5.57143V9.90476H6.94737V5.57143H5.05263ZM0 13H12V11.1429H0V13ZM8.8421 5.57143V9.90476H10.7368V5.57143H8.8421ZM6 0L0 3.09524V4.33333H12V3.09524L6 0Z" fill="white"/>
                        </svg>
                        <span>EARTHBANK....1234 STATEMENT</span>
                    </div>
                    <div class="info-popup__body">
                        <div class="transaction">
                            <div class="transaction__head transaction__row">
                                <div class="transaction__col">Amount</div>
                                <div class="transaction__col">Transaction</div>
                            </div>
                            <div class="transaction__body transaction__row">
                                <div class="transaction__col">$0.50</div>
                                <div class="transaction__col">EBOX* CODE 5678</div>
                            </div>
                            <div class="transaction__footer transaction__row">
                                <div class="transaction__col">$13.00</div>
                                <div class="transaction__col">GROCERIES</div>
                            </div>
                        </div>
                    </div>
                    <div class="info-popup__footer">
                        The statement will include a four digit code. Some bank portals will require you to expand the view and the four digit code will be in the description.
                    </div>
                </div>
            </div>
            <button (click)="infoPopupShown = !infoPopupShown" class="info-popup__button">i</button>
        </div>
    </div>
</div>
