import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../service/sell/existingUser.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {UserStatusService} from "../../service/userStatus.service";
import {USER_STATUS} from "../../config/user_status";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': '',
          '[class.is-menu]': 'menu',}
})
export class SettingsComponent implements OnInit {
    userStatus;
    USER_STATUS = USER_STATUS;
    menu = false;
    userId;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private userStatusService: UserStatusService,
                private popupComponent: PopupComponent,
                private existingUserService: ExistingUserService) {

    }


    ngOnInit() {
        this.loaderService.hide();
        this.setUserStatus();
    }

    setUserStatus() {
        this.loaderService.show();
        this.userStatusService.getUserStatus().subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    // this.userStatus = data.result.status;
                    this.userStatus = Object.keys(USER_STATUS).find(key => USER_STATUS[key] === data.result.status);
                    //console.log(this.userStatus)
                }
            } catch (e) {

            }


        }, (error) => {
            this.loaderService.hide();
        });
    }

    askFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `You are about to freeze your account. No data will be saved. Do you wish to proceed?`}, (status) => {
            if (status) {
                this.freezeUser();
            }
        });
    }

    freezeUser() {
        this.userStatusService.freeze().subscribe(data => {

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.menu = false;
                    this.setUserStatus();
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    askUnFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to freeze the user?`}, (status) => {
            if (status) {
                this.unFreezeUser();
            }
        });
    }

    unFreezeUser() {
        this.userStatusService.activate().subscribe(data => {

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.menu = false;
                    this.setUserStatus();
                } else {
                    this.popupComponent.showPopupError({text:data.message});

                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'DELETE ACCOUNT<svg><rect></rect></svg>',
            style: 'swal-agreement--error'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.userStatusService.delete().subscribe(data => {

            try {
                if(data.status){
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.menu = false;
                    this.setUserStatus();
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }
}
