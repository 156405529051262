import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {FundsService} from "../../../service/settings/funds.service";
import {DomSanitizer} from "@angular/platform-browser";
import {PROJECT_CONFIG} from "../../../config/project";

@Component({
    selector: 'app-funds',
    templateUrl: './funds.component.html',
    styleUrls: ['./funds.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': '',
        '[class.is-menu]': 'frame',}
})
export class FundsComponent implements OnInit {
    sellForm: FormGroup;
    methods;
    frame = false;
    frameUrl;
    activeMethod;
    @ViewChild('iframe', {static: false}) iframe: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private fundsService: FundsService,
                public sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    ngOnInit() {
        this.getPaymentMethods()

        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
            method: [null, Validators.required],
        });
    }

    getPaymentMethods(){
        this.loaderService.show();

        this.fundsService.getPayments().subscribe(data => {
            this.loaderService.hide();

            try {
                if (data.status) {
                    this.methods = data.result;
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });

    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        let method = this.sellForm.controls.method.value;


        if (!this.methods.length) {
            this.popupComponent.showPopupError({text: 'Please enter your payment method'});
        } else {
            if (this.methods.length > 1 && method === null) {
                this.popupComponent.showPopupError({text: 'Please select the payment method to use'});
            } else {
                if (method === null) {
                    this.sellForm.controls.method.setValue(method);
                    this.activeMethod = this.methods[0];
                }
                const name = this.getPaymentName(this.activeMethod);
                this.popupComponent.showPopupAgreement({text: `${name} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
                    if (status) {
                        this.submit();
                    }
                });
            }
        }


    }

    getPaymentName(data){
        if(this.isCard(data.payment_method)){
            return data.description+' - '+data.last_four;
        }else if(this.isPaypal(data.payment_method)){
            return 'PayPal - '+data.email;
        }

        return '';
    }

    changeMethod(e, method){
        this.activeMethod = method;

    }

    submit() {
        const amount = this.sellForm.controls.amount.value;

        let paymentMethod = this.activeMethod.payment_method;

        this.loaderService.show();
        this.fundsService.addFunds(this.activeMethod.id,paymentMethod, amount).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    if(this.isCard(paymentMethod)){
                        //this.sellForm.reset();

                        this.sellForm.controls.amount.setValue('');

                        this.popupComponent.showPopupSuccess({text:data.message});
                    }else if(this.isPaypal(paymentMethod)){
                        //this.sellForm.reset();

                        this.sellForm.controls.amount.setValue('');

                        var win = window.open(data.link, '_blank');
                        win.focus();
                        //this.frameUrl = this.getLink(data.link);

                        //this.showFrame();
                    }
                } else {
                    this.popupComponent.showPopupError({text:data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        });
    }

    showFrame() {
        this.frame = true;
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    closeFrame() {
        this.frame = false;
    }

    isCard(method){
        if(PROJECT_CONFIG.paymentMethodCard == method){
            return true;
        }

        return false;
    }

    isPaypal(method){
        if(PROJECT_CONFIG.paymentMethodPaypal == method){
            return true;
        }

        return false;
    }
}

