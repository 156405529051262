import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MAT_DATE_FORMATS,
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {APP_DATE_FORMATS, SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";

import {PrizesComponent} from "./prizes/prizes.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {InstallComponent} from "./install/install.component";
import {RewardsComponent} from "./rewards/rewards.component";
import {PaypalComponent} from "./prizes/paypal/paypal.component";
import {MailCheckComponent} from "./prizes/mail-check/mail-check.component";
import {NewEmailComponent} from "./prizes/paypal/new-email/new-email.component";
import {NewAddressComponent} from "./prizes/mail-check/new-address/new-address.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {PaypalSettingsComponent} from "./settings/paypal-settings/paypal-settings.component";
import {AddAccountComponent} from "./settings/paypal-settings/add-account/add-account.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";
// import {PinComponent} from "./login2/pin/pin.component";




@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        // PinComponent,

        HomeComponent,

        PaypalPaymentsSuccessComponent,
        PaypalPaymentsUnsuccessComponent,

        RewardsComponent,
        RewardsFormComponent,

        PrizesComponent,
        PaypalComponent,
        NewEmailComponent,

        MailCheckComponent,
        NewAddressComponent,

        SettingsComponent,
        ContactInfoComponent,

        InvestmentsComponent,

        CardComponent,
        NewCardComponent,
        CardMenuComponent,
        EditCardComponent,
        VerifyCardComponent,

        PaypalSettingsComponent,
        AddAccountComponent,

        AddressesSettingsComponent,
        AddAddressComponent,

        FundsComponent,

        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        // ChartsModule,
        NgxCurrencyModule,
        // CarouselModule,
        // SwiperModule,
        // SlickCarouselModule
        DemoMaterialModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
        // {
        //     provide: SWIPER_CONFIG,
        //     useValue: DEFAULT_SWIPER_CONFIG
        // }
    ],
    exports: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent
    ],
    entryComponents: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
