import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CARD_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class UserCardService {
    private config = USER_CARD_CONFIG;

    constructor(private http: HttpClient) {

    }

    getCards() {
        return this.http.post<any>(this.config.cards, {});
    }

    getCardInfo(id) {
        return this.http.post<any>(this.config.card, {id});
    }

    deleteCard(id) {
        return this.http.post<any>(this.config.delete, {id});
    }

    resendCode(id) {
        return this.http.post<any>(this.config.resendCode, {id});
    }

    checkVerify(id) {
        return this.http.post<any>(this.config.checkVerify, {id});
    }

    verifyCard(code, id) {
        return this.http.post<any>(this.config.verify, {code, id});
    }

    addCard(card, month,year, cvv, name) {
        return this.http.post<any>(this.config.add, {card, month,year, cvv, name});
    }

    saveCard(id, month,year, name) {
        return this.http.post<any>(this.config.save, {id, month,year, name});
    }
}
