/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./home.component";
import * as i5 from "../../modules/auth/_services/authentication.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "child"]], [[2, "child--blured", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 35, "div", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "a", [["class", "menu__link"], ["routerLinkActive", "is-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), i1.ɵdid(7, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, ":svg:svg", [["height", "30"], ["width", "35"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "a", [["class", "menu__link"], ["routerLinkActive", "is-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(18, 0, null, null, 1, ":svg:svg", [["height", "33"], ["width", "29"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#prizes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 7, "a", [["class", "menu__link"], ["routerLinkActive", "is-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), i1.ɵdid(23, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(26, 0, null, null, 1, ":svg:svg", [["height", "30"], ["width", "34"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#investment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 7, "a", [["class", "menu__link"], ["routerLinkActive", "is-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(30, 1), i1.ɵdid(31, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(34, 0, null, null, 1, ":svg:svg", [["height", "30"], ["width", "30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#settings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "button", [["class", "menu__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.menu = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, ":svg:svg", [["height", "7"], ["width", "29"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#menu-dots"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 7, "div", [["class", "addition-menu"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 4, "ul", [["class", "addition-menu__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "li", [["class", "addition-menu__item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions"])), (_l()(), i1.ɵeld(43, 0, null, null, 1, "li", [["class", "addition-menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "button", [["class", "addition-menu__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.menu = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_3 = _ck(_v, 6, 0, "/home/rewards"); _ck(_v, 5, 0, currVal_3); var currVal_4 = "is-active"; _ck(_v, 7, 0, currVal_4); var currVal_7 = _ck(_v, 14, 0, "/home/prizes"); _ck(_v, 13, 0, currVal_7); var currVal_8 = "is-active"; _ck(_v, 15, 0, currVal_8); var currVal_11 = _ck(_v, 22, 0, "/home/investments"); _ck(_v, 21, 0, currVal_11); var currVal_12 = "is-active"; _ck(_v, 23, 0, currVal_12); var currVal_15 = _ck(_v, 30, 0, "/home/settings"); _ck(_v, 29, 0, currVal_15); var currVal_16 = "is-active"; _ck(_v, 31, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 13).target; var currVal_6 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 21).target; var currVal_10 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_9, currVal_10); var currVal_13 = i1.ɵnov(_v, 29).target; var currVal_14 = i1.ɵnov(_v, 29).href; _ck(_v, 28, 0, currVal_13, currVal_14); var currVal_17 = _co.menu; _ck(_v, 39, 0, currVal_17); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i4.HomeComponent, [i5.AuthenticationService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i4.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
