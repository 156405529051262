import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {ADDRESSES_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    private config = ADDRESSES_CONFIG;

    constructor(private http: HttpClient) {

    }

    getAddresses() {
        return this.http.post<any>(this.config.addresses, {});
    }

    deleteAddress(id) {
        return this.http.post<any>(this.config.delete, {id});
    }

    addAddress(address) {
        return this.http.post<any>(this.config.add, {address});
    }
}
