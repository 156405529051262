<div class="page page--quaternary">
    <div class="page__inner">
        <a [routerLink]="['/home/settings']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">CREDIT/DEBIT CARD</h1>
        <div class="page__centered">
            <a [routerLink]="['/home/settings/card/new-card']" class="button snake-btn is-notouched">
                ADD NEW CARD
                <svg><rect></rect></svg>
            </a>
        </div>
        <div class="page__body">
            <div class="cards">
                <div *ngFor="let card of cards" class="cards__item" (click)="viewCardInfo(card)">
                    <div class="cards__left">
                        <div class="cards__title">{{card.description}}</div>
                        <div class="cards__subtitle">ending in {{card.last_four}}</div>
                    </div>
                    <div class="cards__right">
                        <button *ngIf="!card.verify" (click)="viewCardInfo(card)" class="cards__btn">
                            <svg width="25" height="25">
                                <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>
                            </svg>
                        </button>
                        <button *ngIf="card.verify" (click)="askDelete(card)" class="cards__btn">
                            <svg width="17" height="22">
                                <use xlink:href="../../../../assets/images/sprite.svg#delete"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

