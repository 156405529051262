<div class="page page--quaternary" *ngIf="card">
    <div class="page__inner">
                <a [routerLink]="['/home/settings/card']" class="page__back">
                    <span class="visually-hidden">Back</span>
                </a>
        <h1 class="page__title">{{card.name}}</h1>

        <div class="page__body">

            <div class="navigation">
                <a [routerLink]="['/home/settings/card/edit', cardId]" class="navigation__button button snake-btn is-notouched">
                    EDIT CARD
                    <svg><rect></rect></svg>
                </a>
                <a (click)="askDelete()" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    DELETE
                    <svg><rect></rect></svg>
                </a>
                <a [routerLink]="['/home/settings/card/verify', cardId]" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    VERIFY
                    <svg><rect></rect></svg>
                </a>
            </div>

        </div>
    </div>
</div>
