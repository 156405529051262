import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_NAME_API,DOMAIN_DEMO_API} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {Stripe} from "../models/stripe";
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {Prizes} from "../models/prizes";
import {UserStatus} from "../models/user-status";
import {UserCard} from "../models/user-card";
import {Paypal} from "../models/paypal";
import {Addresses} from "../models/addresses";
import {Funds} from "../models/funds";
import {UserContact} from "../models/user-contact";

let API = DOMAIN_API + '/client';
if(window.location.hostname == DOMAIN_DEMO_NAME_API){
    API = DOMAIN_DEMO_API + '/client';
}


const AUTH = API + '/users/session';
const USERS = API + '/users';

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: AUTH + '/login',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    checkPhoneAndSendPin: USERS + '/phone/check',
};

export const PRIZES_CONFIG: Prizes = {
    data: API + '/balance/history',
    paypalEmails: API+'/paypal/emails',
    checkAddresses: API+'/check/address',
    mailCheckCreatePayout: API+'/balance/payout/check',
    paypalCreatePayout: API+'/balance/payout/paypal',
    checkAddAddress: API+'/check/address/add',
    paypalAddEmail: API+'/paypal/emails/add',
};

export const USER_CONTACT_CONFIG: UserContact = {
    info: API+'/users/user/detail',
    save: API+'/users/contact/save'
}

export const USER_CONFIG: UserStatus = {
    status: API+'/users/user/detail',
    freeze: API+'/users/user/freeze',
    activate: API+'/users/user/activate',
    delete: API+'/users/user/delete',
}

export const USER_CARD_CONFIG: UserCard = {
    cards: API+'/cards/list',
    card: API+'/cards/card',
    delete: API+'/cards/card/delete',
    verify: API+'/cards/card/verify',
    add: API+'/cards/card/add',
    save: API+'/cards/card/save',
    checkVerify: API+'/cards/card/checkVerify',
    resendCode: API+'/cards/card/sendCode',
}

export const PAYPAL_CONFIG: Paypal = {
    accounts: API+'/paypal/emails/lists',
    delete: API+'/paypal/emails/delete',
    add: API+'/paypal/emails/add',
    paymentSuccess: API+'/payments/paypal/paymentSuccess',
    paymentUnsuccess: API+'/payments/paypal/paymentFail',
}

export const ADDRESSES_CONFIG: Addresses = {
    addresses: API+'/check/address',
    delete: API+'/check/address/delete',
    add: API+'/check/address/add',
}

export const FUNDS_CONFIG: Funds = {
    payments: API+'/payments/method/available',
    add: API+'/payments/create'
}

export const NEW_USER_CONFIG: NewUser = {
    save: API + '/users/user/createCustomer',
};

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: API + '/users/users',
    userInfo: API + '/users/user/paymentHistory',
    sell: API + '/users/user/deposit',
    freeze: API + '/users/user/freeze',
    delete: API + '/users/user/delete',
};



export const STRIPE_CONFIG: Stripe = {
    getPublishableKey: API+'/app/stripe/public',
};
