import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../shared/popup/popup.component";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-rewards',
    templateUrl: './rewards.component.html',
    styleUrls: ['./rewards.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class RewardsComponent implements OnInit {
    userPin;

    constructor(private popupComponent: PopupComponent,
                private auth:AuthenticationService) {

    }


    ngOnInit() {
        this.userPin = this.auth.getUserRiverCode();
    }

    onSweeps() {
        this.popupComponent.showPopupAgreement({text: `You are being redirected to a third-party provider not associated with eBox Enterprises. Please confirm to proceed.`,
            confirmButtonText: 'SWEEP REWARDS<svg><rect></rect></svg>',
            style: 'swal-agreement--success'}, (status) => {
            if (status) {
                this.goToSweep();
            }
        });
    }

    goToSweep() {
        //window.location.replace('https://www.google.com/');
        var win = window.open('https://river777.net', '_blank');
        win.focus();
    }

    copyToBuffer() {
        let tempInput = document.createElement('input');
        tempInput.value = this.userPin;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.popupComponent.showPopupSuccess({text: 'Code has been successfully copied!'});
    }
}
