import {Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {StripeService} from "../../../../service/stripe/stripe.service";

@Component({
    selector: 'app-edit-card',
    templateUrl: './edit-card.component.html',
    styleUrls: ['./edit-card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class EditCardComponent implements OnInit {
    newCardForm: FormGroup;
    card;
    cardId;
    cardPlaceholder = "Card Number";

    constructor(private formBuilder: FormBuilder,
                private userCardService: UserCardService,
                private loaderService: LoaderService,
                private route: ActivatedRoute,
                private router: Router,
                private stripeService:StripeService,
                private popupComponent: PopupComponent,) {

    }


    ngOnInit() {
        this.newCardForm = this.formBuilder.group({
            card_number: [null],
            exp: [null, Validators.required],
            cvv: [null],
            card_nickname: [null, Validators.required]
        });

        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo();
        });


    }

    getCardInfo() {
        this.loaderService.show();
        this.userCardService.getCardInfo(this.cardId).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    this.card = data.result;

                    if(this.card.last_four != ''){
                        this.cardPlaceholder = '**** **** **** '+this.card.last_four;
                    }

                    //this.newCardForm.controls.card_number.setValue('**** **** **** '+this.card.last_four);
                    this.newCardForm.controls.exp.setValue(this.card.month+'/'+this.card.year);
                    //this.newCardForm.controls.cvv.setValue(this.card.cvv);
                    this.newCardForm.controls.card_nickname.setValue(this.card.description);
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
        })
    }

    onSubmit() {
        const number = this.newCardForm.controls.card_number.value;
        const exp = this.newCardForm.controls.exp.value;
        const cvv = this.newCardForm.controls.cvv.value;
        const nickname = this.newCardForm.controls.card_nickname.value;
        const id = this.cardId;

        let expMonth = exp.substr(0, 2);
        let expYear = exp.substr(3, 4);
        if(expMonth != '' && expYear != ''){
            let $this = this;

            $this.loaderService.show();
            $this.userCardService.saveCard(id, expMonth, expYear,  nickname).subscribe(data => {
                $this.loaderService.hide();

                try {
                    if (data.status) {
                        $this.popupComponent.showPopupSuccess({text: data.message});
                        $this.newCardForm.reset();
                        $this.router.navigate(['/home/settings/card']);
                    } else {
                        $this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    $this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                }

            }, (error) => {
                $this.loaderService.hide();
                $this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            });

        }else{
            this.popupComponent.showPopupError({text: 'Incorrect data format'});
        }

    }
}
