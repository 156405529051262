import { USER_CONTACT_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactInfoService = /** @class */ (function () {
    function ContactInfoService(http) {
        this.http = http;
        this.config = USER_CONTACT_CONFIG;
    }
    ContactInfoService.prototype.getUserContacts = function () {
        return this.http.post(this.config.info, {});
    };
    ContactInfoService.prototype.saveUserContacts = function (first_name, last_name, email) {
        return this.http.post(this.config.save, { first_name: first_name, last_name: last_name, email: email });
    };
    ContactInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactInfoService_Factory() { return new ContactInfoService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactInfoService, providedIn: "root" });
    return ContactInfoService;
}());
export { ContactInfoService };
