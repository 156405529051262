import { APP_DATE_FORMATS } from '../shared/shared.module';
var ɵ0 = APP_DATE_FORMATS;
// import {PinComponent} from "./login2/pin/pin.component";
var ChatQuizModule = /** @class */ (function () {
    function ChatQuizModule() {
    }
    return ChatQuizModule;
}());
export { ChatQuizModule };
export { ɵ0 };
