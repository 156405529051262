/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rewards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./rewards.component";
import * as i4 from "../../shared/popup/popup.component";
import * as i5 from "../../modules/auth/_services/authentication.service";
var styles_RewardsComponent = [i0.styles];
var RenderType_RewardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RewardsComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_RewardsComponent as RenderType_RewardsComponent };
function View_RewardsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "rewards__pin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "rewards__code"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "rewards__save"], ["data-clipboard-action", "copy"], ["title", "copy number"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToBuffer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["viewBox", "0 0 24 24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M 6 2 L 6 4 L 20 4 L 20 18 L 22 18 L 22 4 C 22 2.895 21.105 2 20 2 L 6 2 z M 4 6 C 2.895 6 2 6.895 2 8 L 2 20 C 2 21.105 2.895 22 4 22 L 16 22 C 17.105 22 18 21.105 18 20 L 18 8 C 18 6.895 17.105 6 16 6 L 4 6 z M 4 8 L 16 8 L 16 20 L 4 20 L 4 8 z M 9 10 L 9 13 L 6 13 L 6 15 L 9 15 L 9 18 L 11 18 L 11 15 L 14 15 L 14 13 L 11 13 L 11 10 L 9 10 z"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userPin; _ck(_v, 2, 0, currVal_0); }); }
export function View_RewardsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page page--primary rewards"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["REWARDS"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "rewards__btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "button snake-btn is-notouched"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSweeps() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" SWEEPS "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardsComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "images"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", ""], ["class", "images__item"], ["src", "../../../assets/images/rewards/rewards-img-1.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", ""], ["class", "images__item"], ["src", "../../../assets/images/rewards/rewards-img-2.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userPin; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_RewardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rewards", [], [[40, "@slideToRight", 0]], null, null, View_RewardsComponent_0, RenderType_RewardsComponent)), i1.ɵdid(1, 114688, null, 0, i3.RewardsComponent, [i4.PopupComponent, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var RewardsComponentNgFactory = i1.ɵccf("app-rewards", i3.RewardsComponent, View_RewardsComponent_Host_0, {}, {}, []);
export { RewardsComponentNgFactory as RewardsComponentNgFactory };
